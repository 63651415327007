import React, { Fragment, useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { Btn, H4, Breadcrumbs } from '../../../../AbstractElements';



const Shifts = () => {
       const userData = [
    {
      id: 1,
      name: "shift1",
      stime: "12:00",
      etime: "18:00",
     
    },
    {
      id: 2,
      name: "JShift2",
      stime: "08:00",
      etime: "14:00",
    
    },
    {
      id: 3,
      name: "Shift3",
      stime: "19:00",
      etime: "06:00",
      
    },
  ];

  const [resetModal, setResetModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const toggleResetModal = () => setResetModal(!resetModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleCreateModal = () => setCreateModal(!createModal);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleReset = (userId) => {
    console.log(`Reset password for user with ID ${userId}`);
    toggleResetModal();
  };

  const handleEdit = (userId) => {
    console.log(`Edit user with ID ${userId}`);
    toggleEditModal();
  };

  const handleDelete = (userId) => {
    console.log(`Delete user with ID ${userId}`);
    setUserIdToDelete(userId);
    toggleDeleteModal();
  };

  const handleCreate = () => {
    console.log("Open create form");
    toggleCreateModal();
  };

  const confirmDelete = () => {
    console.log(`Deleting user with ID ${userIdToDelete}`);
    toggleDeleteModal();
  };

    return (
        <Fragment>
         <Breadcrumbs mainTitle="Shifts" parent="Attendance" title="Shifts"/>
      <Card>
         <div className="mb-3" style={{ marginLeft: "1100px", marginTop: "20px" }}>
     
        <Link to={`${process.env.PUBLIC_URL}/approvals-two/:layout`}>
          <Btn  id="addScheduleButton">
            <i className="fa fa-plus"></i> Add New Schedule
          </Btn>
        </Link>
      
    </div>
        <CardBody>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Shift name</th>
                <th>Shift start time</th>
                <th>Shift end time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user) => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.stime}</td>
                  <td>{user.etime}</td>
                  
                  
                  <td>
                    
                    <div className='row'>
                      <div className='col-2'>
                  
                      <i className="fa fa-edit" style={{ color: "blue", textAlign: "center" }}></i>
                   </div>
                   
                  <div className='col-2'>
                      <i className="fa fa-trash" style={{ color: "red", textAlign: "center" }}></i>
                   </div>
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={resetModal} toggle={toggleResetModal}>
        <ModalHeader toggle={toggleResetModal}>
          Reset Password Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to reset the password?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleResetModal}>
            Yes, Reset
          </Button>{" "}
          <Button color="secondary" onClick={toggleResetModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Shift</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Shift Name</Label>
            <Input type="text" id="" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Shift Start Time</Label>
            <Input type="time" id="" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Shift End Time</Label>
            <Input type="time" id="" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleEditModal}>
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          Delete Shift Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this shift?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>
            Yes, Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={createModal} toggle={toggleCreateModal}>
        <ModalHeader toggle={toggleCreateModal}>Create Shift</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Shift Name</Label>
            <Input type="text" id="shift name" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Shift Start Time</Label>
            <Input type="time" id="start time" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Shift End Time</Label>
            <Input type="time" id="end time" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleCreateModal}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggleCreateModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
    );
};

export default Shifts;