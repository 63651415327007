import React, { Fragment, useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Btn, H4, Breadcrumbs } from "../../../../AbstractElements";
import { tableColumns } from "../../../../Data/Table/Defaultdata";
import { Link } from "react-router-dom";
import axios from "axios";

const EmployeeList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://punchr.ofeasy.lk/api/organization/employees', {
          headers: {
            Accept: 'application/json',
            'X-Tenant': 'client_a',
            Authorization: 'Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56',
          },
        });

        console.log('API Response:', response.data);

        if (Array.isArray(response.data.data)) {
          setData(response.data.data);
        } else {
          console.error('API response data is not an array:', response.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows.map(
          (r) => r.title
        )}?`
      )
    ) {
      setToggleDelet(!toggleDelet);

      setData(
        data.filter((item) =>
          selectedRows.filter((elem) => elem.id === item.id).length > 0
            ? false
            : true
        )
      );
      setSelectedRows([]);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Employee List" parent="Employee List" />
      <div className="mb-3" style={{ marginLeft: "980px" }}>
        <Link to={`${process.env.PUBLIC_URL}/employeeadd/:layout`}>
          <Btn>
            <i className="fa fa-plus"></i> Add Employee
          </Btn>
        </Link>
      </div>

      {selectedRows.length !== 0 && (
        <div
          className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>
            Delete Selected Data..!
          </H4>
          <Btn
            attrBtn={{ color: "danger", onClick: () => handleDelete() }}
          >
            Delete
          </Btn>
        </div>
      )}
      <DataTable
        data={data}
        columns={tableColumns}
        striped={true}
        center={true}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelet}
      />
    </Fragment>
  );
};

export default EmployeeList;
