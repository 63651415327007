import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import Stepper from "awesome-react-stepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCalendar,
  faMapMarkerAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./setting.css";

const stepIcons = [faUser, faEnvelope, faCalendar];

const SettingTest = () => {
  const [isLevelModalOpen, setLevelModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isAddProjOpen, setAddProjOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const toggleAddModal = () => {
    setAddModalOpen(!isAddModalOpen);
  };

  const toggleAddProj = () => {
    setAddProjOpen(!isAddProjOpen);
  };

  const handleSubmit = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    } else {
      alert("Thank you!!! Final Step");
      toggleLevelModal();
    }
  };

  const toggleLevelModal = () => {
    setLevelModalOpen(!isLevelModalOpen);
    setCurrentStep(0);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="stepperSubDiv">
            <Stepper
              fillStroke="#800080"
              activeColor="#800080"
              activeProgressBorder="2px solid #800080"
              submitBtn={<button className="stepperBtn">Submit</button>}
              continueBtn={<button className="stepperBtn">Next</button>}
              backBtn={<button className="stepperBtn">Back</button>}
              onSubmit={handleSubmit}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              iconComponent={(step, isActive) => (
                <FontAwesomeIcon
                  icon={stepIcons[step]}
                  size="lg"
                  color={isActive ? "#800080" : "#ccc"}
                />
              )}
            >
              <div className="stepperSubDiv">
                <h4>Setup Location</h4>
                <Card>
                  <CardBody style={{ textAlign: "end" }}>
                    <Button onClick={toggleAddModal} style={{ marginEnd: 0 }}>
                      Add
                    </Button>
                  </CardBody>

                  <CardBody style={{ textAlign: "center" }}>
                    <img
                      src="https://www.freeiconspng.com/thumbs/empty-image-icon-png/inbox-empty-image-icon-26.png"
                      alt="No Data"
                      style={{ width: "30%", display: "block", margin: "auto" }}
                    />
                  </CardBody>
                </Card>
              </div>

              <div className="stepperSubDiv">
                <h4>Setup project</h4>
                <Card>
                  <CardBody style={{ textAlign: "end" }}>
                    <Button onClick={toggleAddProj} style={{ marginEnd: 0 }}>
                      Add
                    </Button>
                  </CardBody>
                  <CardBody style={{ textAlign: "center" }}>
                    <img
                      src="https://www.freeiconspng.com/thumbs/empty-image-icon-png/inbox-empty-image-icon-26.png"
                      alt="No Data"
                      style={{ width: "30%", display: "block", margin: "auto" }}
                    />
                  </CardBody>
                </Card>
              </div>

              <div className="stepperSubDiv">
                <h4>Setup Devices</h4>
                <div className="row">
                  <div className="col">
                    <FormGroup className="pt-4" style={{ width: "100%" }}>
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                      />
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup className="pt-4" style={{ width: "100%" }}>
                      <label>Type</label>
                      <input
                        type="text"
                        name="type"
                        className="form-control"
                        placeholder="type"
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <FormGroup style={{ width: "100%" }}>
                      <label>Device Number</label>
                      <input
                        type="text"
                        name="deviceno"
                        className="form-control"
                        placeholder="Eg: 001"
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <FormGroup style={{ width: "100%" }}>
                      <label>IP Address</label>
                      <input
                        type="text"
                        name="ip"
                        className="form-control"
                        placeholder="ipaddress"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="stepperSubDiv">
                <h4>Setup Project</h4>
                <div className="container">
                  <Card>
                    <div
                      className="row overflow-scroll"
                      style={{ overflowY: "auto", maxHeight: "300px" }}
                    >
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-4">
                        <Card className="locationcard">
                          <CardBody>
                            <h6>Project Abc</h6>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "red" }}
                            />{" "}
                            Dubai
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className="btn btn-sm btn-primary"
                                style={{ marginTop: "60px", fontSize: "80%" }}
                              >
                                View Map
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Stepper>
          </div>
        );
      case 2:
        return (
          <div className="stepperSubDiv">
            <h4>Completed!</h4>
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#28a745" />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <CardBody>
        <Card>
          <div className="levelbutton">
            <Button className="button-style" onClick={toggleLevelModal}>
              Testing setting
            </Button>
          </div>

          <Modal isOpen={isLevelModalOpen} toggle={toggleLevelModal} size="lg">
            <ModalHeader
              toggle={toggleLevelModal}
              style={{ fontFamily: "Times New Roman" }}
            >
              Welcome to PunchR
            </ModalHeader>

            <ModalBody>
              {currentStep === 0 ? (
                <img
                  src="https://bst.icons8.com/wp-content/uploads/2020/02/teamwork-illustrations-design.jpg"
                  style={{ width: "100%" }}
                  alt="Alt Text"
                />
              ) : (
                <div>{renderStepContent()}</div>
              )}
            </ModalBody>
            <ModalFooter>
              {currentStep === 0 && (
                <Button color="primary" onClick={handleSubmit}>
                  Continue
                </Button>
              )}
            </ModalFooter>
          </Modal>

          <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
            <ModalHeader toggle={toggleAddModal}>Setup Location</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Address"
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      placeholder="country"
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Coordinates</label>
                    <input
                      type="text"
                      name="coordinates"
                      className="form-control"
                      placeholder="coordinates"
                    />
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleAddModal}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggleAddModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isAddProjOpen} toggle={toggleAddProj}>
            <ModalHeader toggle={toggleAddProj}>Setup Projects</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="name"
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Locaton</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      placeholder="location"
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Coordinates</label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      placeholder="coordinates"
                    />
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleAddProj}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggleAddProj}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Card>
      </CardBody>
    </Fragment>
  );
};

export default SettingTest;
