import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table 
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { Btn } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import "../Screen/approvals.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLevelUpAlt,
  faCube,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const ApprovalsTwo = () => {
  const [isLevelModalOpen, setLevelModalOpen] = useState(false);
  const [isEntityModalOpen, setEntityModalOpen] = useState(false);
  const [isApprovalModalOpen, setApprovalModalOpen] = useState(false);
  const iconStyle = { marginRight: "8px" };
  const handleButtonClick = (rowIndex) => {
  // Handle button click for the specific row
  console.log(`Button clicked for Row ${rowIndex + 1}`);
  // Add your logic here
};


  const toggleLevelModal = () => {
    setLevelModalOpen(!isLevelModalOpen);
  };

  const toggleEntityModal = () => {
    setEntityModalOpen(!isEntityModalOpen);
  };

  const toggleApprovalModal = () => {
    setApprovalModalOpen(!isApprovalModalOpen);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Approvals" parent="Approvals" title="Approvals List"/>

      <CardBody>
        <Card>
          <div className="levelbutton">
            <Button
              className="button-style "
              onClick={toggleLevelModal}
            >
              <FontAwesomeIcon
                icon={faLevelUpAlt}
                className="icon"
                style={iconStyle}
              />
              Approval Level
            </Button>
          </div>
          <div className="entitybutton">
            <Button className="button-style entity" onClick={toggleEntityModal}>
              <FontAwesomeIcon
                icon={faCube}
                className="icon"
                style={iconStyle}
              />
              Approval Entity
            </Button>
          </div>
          <div className="addapprovalbutton">
            <Button className="button-style" onClick={toggleApprovalModal}>
              <FontAwesomeIcon
                icon={faPlus}
                className="icon"
                style={iconStyle}
              />
              Add Approval
            </Button>
          </div>

          <div >
          <Button className="submitbutton d-flex">
  <FontAwesomeIcon icon={faPlus} className="icon" style={iconStyle} />
  Submit
</Button>


            </div>

          <Modal isOpen={isLevelModalOpen} toggle={toggleLevelModal}>
            <ModalHeader toggle={toggleLevelModal}>
              Approval Level Form
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleInput">Location</Label>
                <Input type="text" name="exampleInput" id="exampleInput" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleInput">Project</Label>
                <Input type="text" name="exampleInput" id="exampleInput" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleInput">Employee</Label>
                <Input type="text" name="exampleInput" id="exampleInput" />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleLevelModal}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggleLevelModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isEntityModalOpen} toggle={toggleEntityModal}>
            <ModalHeader toggle={toggleEntityModal}>
              Approval Entity Form
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="entityInput">Entity Input</Label>
                <Input type="text" name="entityInput" id="entityInput" />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleEntityModal}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggleEntityModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isApprovalModalOpen} toggle={toggleApprovalModal}>
            <ModalHeader toggle={toggleApprovalModal}>
              Add Approvers
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Table borderless>
            <tbody>
  {[...Array(5)].map((_, rowIndex) => (
    <tr key={rowIndex}>
      <td>Row {rowIndex + 1}, Column 1</td>
      <td>Row {rowIndex + 1}, Column 2</td>
      <td>
        {/* Add a button in the third column */}
        <Button color="primary" onClick={() => handleButtonClick(rowIndex)}>
          Add
        </Button>
      </td>
    </tr>
  ))}
</tbody>

          </Table>
              </FormGroup>
            </ModalBody>
          </Modal>
        </Card>
      </CardBody>
    </Fragment>
  );
};

export default ApprovalsTwo;
