import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";

const VisitsClients = () => {
  const [clientVisits, setClientVisits] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://punchr.ofeasy.lk/api/organization/clientVisits", {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
          },
        });

        // Assuming the API response is an array, check the structure
        if (Array.isArray(response.data)) {
          setClientVisits(response.data);
        } else {
          console.error("Invalid API response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching client visits:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Client Visits List" parent="Client Management" title="Clients Visits" />
      <Card>
        <CardBody>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>ID</th>
                <th>Client Name</th>
                <th>Employee Name</th>
                <th>Date</th>
                <th>Purpose </th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {clientVisits.map((visit) => (
                <tr key={visit.id}>
                  <td>{visit.client_id}</td>
                  <td>{visit.client_name}</td>
                  <td>{visit.employee_name}</td>
                  <td>{visit.date}</td>
                  <td>{visit.purpose}</td>
                  <td>{visit.location}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default VisitsClients;
