import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  Tooltip,
  Button,
  Table,
} from "reactstrap";
import { H4, Breadcrumbs } from "../../../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./project.css";
import axios from "axios";

const MySwal = withReactContent(Swal);

const Projects = () => {
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [formData, setFormData] = useState({
    branch_id: "",
    name: "",
    lat: "",
    lang: "",
    status: "",
  });
  const [projectId, setProjectId] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [branchOptions, setBranchOptions] = useState([]);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  {
    /* listing function */
  }
  useEffect(() => {
    const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://punchr.ofeasy.lk/api/organization/projects",
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      console.log("API Response:", response);

      if (response.status === 200) {
        const result = response.data.data;
        setData(result);
      } else {
        console.error(
          "Failed to fetch project data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching project data:", error.message);
    } finally {
      setLoading(false);
    }
  };


    const fetchBranchOptions = async () => {
      try {
        const branchResponse = await axios.get(
          "https://punchr.ofeasy.lk/api/organization/branches",
          {
            headers: {
              Accept: "application/json",
              "X-Tenant": "client_a",
              Authorization:
                "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
            },
          }
        );

        console.log("Branch API Response:", branchResponse);

        if (branchResponse.status === 200) {
          const branchData = branchResponse.data.data;
          setBranchOptions(branchData);
        } else {
          console.error(
            "Failed to fetch branch data:",
            branchResponse.status,
            branchResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching branch data:", error.message);
      }
    };

    fetchData();
    fetchBranchOptions();
  }, []);

  const handleEdit = (item) => {
    setProjectId(item.id);
    setFormData({
      branch_id: item.branch.id || "",
      name: item.name || "",
      lat: item.lat || "",
      lang: item.lang || "",
      status: item.status || "",
    });

    setEditModal(true);
  };

  {
    /* Edit Function */
  }
  // const handleSave = async () => {
  //   try {
  //     const response = await axios.put(
  //       `https://punchr.ofeasy.lk/api/organization/projects/${projectId}`,
  //       formData,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "X-Tenant": "client_a",
  //           Authorization:
  //             "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
  //         },
  //       }
  //     );

  //     console.log("Update API Response:", response);

  //     if (response.status === 200) {
  //       // Use fetchData function after successful update
  //       fetchData();
  //     } else {
  //       console.error(
  //         "Failed to update project data:",
  //         response.status,
  //         response.statusText
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error updating project data:", error.message);
  //   } finally {
  //     setEditModal(false);
  //   }
  // };

  const handleCancel = () => {
    setFormData({
      branch_id: "",
      name: "",
      lat: "",
      lang: "",
      status: "",
    });

    setEditModal(false);
  };

  const handleAddNewProject = () => {
    setFormData({
      branch_id: "",
      name: "",
      lat: "",
      lang: "",
      status: "",
    });
    setCreateModal(true);
  };

  {
    /* Create Fuction */
  }
  const handleCreateProject = async () => {
    try {
      console.log("Creating project with data:", formData);

      const response = await axios.post(
        "https://punchr.ofeasy.lk/api/organization/projects",
        formData,
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      console.log("Create Project API Response:", response);

      if (response.status === 200) {
        console.log("Project created successfully!");
        
      } else {
        console.error(
          "Failed to create project:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating project:", error.message);
    } finally {
      setCreateModal(false);
    }
    window.location.reload();
  };

  const handleCancelCreate = () => {
    setFormData({
      branch_id: "",
      name: "",
      lat: "",
      lang: "",
      status: "",
    });

    setCreateModal(false);
  };

  {
    /* Delete Function  */
  }
  const handleDelete = (projectId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `https://punchr.ofeasy.lk/api/organization/projects/${projectId}`,
            {
              headers: {
                Accept: "application/json",
                "X-Tenant": "client_a",
                Authorization:
                  "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
              },
            }
          );

          console.log("Delete API Response:", response);

          if (response.status === 200) {
           
          } else {
            console.error(
              "Failed to delete project:",
              response.status,
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error deleting project:", error.message);
        }
      }
    });
  };

  const handleView = (item) => {
    setFormData({
      branch_id: item.branch.id || "",
      name: item.name || "",
      lat: item.lat || "",
      lang: item.lang || "",
      status: item.status || "",
    });

    setViewModal(true);
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Projects List"
        parent="Organization"
        title="Projects"
      />
      <div className="d-flex justify-content-end mt-3">
        <Tooltip
          placement="bottom"
          target="addUserButton"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
        >
          Add New Project
        </Tooltip>
        <Button
          id="addUserButton"
          color="warning"
          onClick={handleAddNewProject}
          className="remove-padding"
        >
          <i className="fa fa-plus"></i> Add New Project
        </Button>
      </div>

      <div className="pt-5">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Card>
            <CardHeader>
              <div className="row">
                <div className="col-6">
                  {/* <H4 attrH4={{ className: "card-title mb-0" }}>
                    Project Table Title
                  </H4> */}
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </CardHeader>
            <div className="table-responsive edit-table">
              <table className="table table-striped">
                <thead className="table-header">
                  <tr>
                    <th scope="col">Branch Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Latitude</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.branch.id}</td>
                        <td>{item.name}</td>
                        <td>{item.lat}</td>
                        <td>{item.lang}</td>
                        <td>{item.status}</td>
                        <td>
                          <div className="row">
                            <div className="col-2">
                              <span
                                className="mr-4 text-primary cursor-pointer"
                                onClick={() => handleEdit(item)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </div>
                            <div className="col-2">
                              <span
                                className="mr-4 text-success cursor-pointer"
                                onClick={() => handleView(item)}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </span>
                            </div>
                            <div className="col-2">
                              <span
                                className="text-danger cursor-pointer"
                                onClick={() => handleDelete(item.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">
                        {loading ? "Loading..." : "No data available"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        )}
      </div>

      {/* Create Modal  */}
      <Modal isOpen={createModal} toggle={() => setCreateModal(!createModal)}>
        <ModalHeader toggle={() => setCreateModal(!createModal)}>
          Create Projects
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="branchid">Branch:</Label>
            <Input
              type="select"
              name="branchid"
              id="branchid"
              value={formData.branch_id}
              onChange={(e) =>
                setFormData({ ...formData, branch_id: e.target.value })
              }
            >
              <option value="" disabled>
                Select Branch
              </option>
              {branchOptions.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="name">Name:</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder="name"
            />
          </FormGroup>
          <FormGroup>
            <Label for="lat">Latitude:</Label>
            <Input
              type="text"
              name="lat"
              id="lat"
              value={formData.lat}
              onChange={(e) =>
                setFormData({ ...formData, lat: e.target.value })
              }
              placeholder="lat"
            />
          </FormGroup>
          <FormGroup>
            <Label for="lang">Longitude:</Label>
            <Input
              type="text"
              name="lang"
              id="lang"
              value={formData.lang}
              onChange={(e) =>
                setFormData({ ...formData, lang: e.target.value })
              }
              placeholder="lang"
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status:</Label>
            <Input
              type="text"
              name="status"
              id="status"
              value={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.value })
              }
              placeholder="status"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreateProject}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={handleCancelCreate}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Edit Modal  */}
      <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)}>
        <ModalHeader toggle={() => setEditModal(!editModal)}>
          Edit Projects
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name:</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="lat">Latitude:</Label>
            <Input
              type="text"
              name="lat"
              id="lat"
              value={formData.lat}
              onChange={(e) =>
                setFormData({ ...formData, lat: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="lang">Longitude:</Label>
            <Input
              type="text"
              name="lang"
              id="lang"
              value={formData.lang}
              onChange={(e) =>
                setFormData({ ...formData, lang: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status:</Label>
            <Input
              type="status"
              name="status"
              id="status"
              value={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.value })
              }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreateProject}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* View Modal  */}
      <Modal isOpen={viewModal} toggle={() => setViewModal(!viewModal)}>
        <ModalHeader toggle={() => setViewModal(!viewModal)}>
          View Project Details
        </ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              <tr>
                <th className="branch-id">Branch ID:</th>{" "}
                <td>{formData.branch_id}</td>
              </tr>
              <tr>
                <th className="branch-id">Name:</th> <td>{formData.name}</td>
              </tr>
              <tr>
                <th className="branch-id">Latitude:</th> <td>{formData.lat}</td>
              </tr>
              <tr>
                <th className="branch-id">Longitude:</th> <td>{formData.lang}</td>
              </tr>
              <tr>
                <th className="branch-id">Status:</th>{" "}
                <td>{formData.status}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setViewModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Projects;
