import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Card,
  CardHeader,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Btn, H4 } from "../../../../../AbstractElements";
import { UserEditTableAPI } from "../../../../../api";
import { ProjectFormTitle } from "../../../../../Constant";

const AddProjects = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      axios.get(UserEditTableAPI).then((res) => setData(res.data));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleDelete = (userId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (isConfirmed) {
      // Perform the delete operation using axios or your preferred method
      // For example: axios.delete(`${UserEditTableAPI}/${userId}`).then(() => fetchData());
    }
  };

  return (
    <Fragment>
      <div className="pt-5">
        <Card>
          <CardHeader>
            <div className="row">
              <div className="col-6">
                <H4 attrH4={{ className: "card-title mb-0" }}>
                  {ProjectFormTitle}
                </H4>

                <div className="card-options">
                  <a
                    className="card-options-collapse"
                    href="#javascript"
                    data-toggle="card-collapse"
                  >
                    <i className="fe fe-chevron-up"></i>
                  </a>
                  <a
                    className="card-options-remove"
                    href="#javascript"
                    data-toggle="card-remove"
                  >
                    <i className="fe fe-x"></i>
                  </a>
                </div>
              </div>
            </div>
          </CardHeader>
          <div>
            <form className="p-5">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Project Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Project Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Branches</label>
                    <select className="form-control">
                      <option value="" selected disabled>
                        Select a Branch
                      </option>
                      <option value="english">Branch 1</option>
                      <option value="spanish">Branch 2</option>
                      <option value="french">Branch 3</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label">Latitude</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Latitude"
                    />
                  </div>
                </div>

                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label">Language</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Language"
                    />
                  </div>
                </div>
              </div>
              <br></br>
              <ModalFooter>
                <Btn attrBtn={{ color: "primary", onClick: toggleModal }}>
                  Save
                </Btn>
              </ModalFooter>
            </form>
          </div>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>Your changes have been saved successfully.</ModalBody>
        <ModalFooter>
          <Btn color="primary" onClick={toggleModal}>
            OK
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddProjects;
