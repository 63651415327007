import React, { Fragment, useContext, useState } from 'react';
import {Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import { Btn, H4 } from '../../../../AbstractElements';
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { Done, All, Doing, CreateNewBranch } from '../../../../Constant';
// import { Breadcrumbs } from '../../../../AbstractElements';
import ProjectContext from '../../../../_helper/Project';
import CusClass from '../../../../Components/Application/Project/Common/CusClass';
import CustomizerContext from '../../../../_helper/Customizer';


const Branches = () => {
      const { layoutURL } = useContext(CustomizerContext);
  const [activeTab, setActiveTab] = useState('1');
  
    return (
        <Fragment>
     
      <Container fluid={true}>
        <Row className="project-card">
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col md="6">
                  <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}><Target />{All}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}><Info />{Doing}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}><CheckCircle />{Done}</NavLink></NavItem>
                  </Nav>
                </Col>
                
                <Col md="6">
                  <div className="text-end">
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/addnewbranch/${layoutURL}`}> <PlusCircle />{CreateNewBranch}</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                    <Link to={`${process.env.PUBLIC_URL}/projects/:layout`}></Link>
                  <TabPane tabId="1">
                    <Row>
                        
                     
                        <CusClass/>
                     
                    </Row>
                  </TabPane>
                  
                  <TabPane tabId="2">
                    <Row>
                      
                      (item.badge === 'Doing' ?
                        <CusClass />
                        : '')
                     
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                     
                      (item.badge === 'Done' ?
                        <CusClass  />
                        : '')
                     
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
    );
};

export default Branches;
