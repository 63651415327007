import React, { useState, Fragment } from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../Constant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Signin = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState(1);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const [resetEmail, setResetEmail] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetEmailChange = (value) => {
    setResetEmail(value);
  };

  const handleResetPasswordChange = (value) => {
    setResetPassword(value);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const loginAuth = async () => {
  try {
    const requestConfig = {
      url: "https://punchr.ofeasy.lk/api/authentication/login",
      method: "post",
      data: {
        email,
        password,
      },
      headers: {
        Accept: "application/json",
        "X-Tenant": "client_a",
      },
    };

    const response = await axios(requestConfig);

    const token = response.data.token;

    // Save the token to local storage
    localStorage.setItem("authToken", token);

    const layoutURL = "dashboard";
    history(`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`);

    toast.success("Successfully logged in!..");
  } catch (error) {
    console.error(error);
    toast.error("Login failed. Please check your credentials.");
  }
};


  const handleForgotEmailChange = (value) => {
    setResetEmail(value);
  };

  const handleForgetPassword = async () => {
    try {
      const forgetPasswordResponse = await axios.post(
        "https://punchr.ofeasy.lk/api/authentication/password/forget",
        {
          email: resetEmail,
        },
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
          },
        }
      );

      
      console.log(forgetPasswordResponse);

      
      setResetPasswordStep(2);
    } catch (error) {
      console.error(error);
      toast.error("Error sending forget password request. Please try again.");
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setResetPasswordStep(1);
  };

  const handleNext = () => {
    if (resetPasswordStep === 1) {
      
      handleForgetPassword();
    } else {
      
      setModalOpen(false);
      toast.success("Password successfully reset!");
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>
                    {selected === "simpleLogin"
                      ? ""
                      : "Sign In With Simple Login"}
                  </H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a
                      className="link"
                      href="#javascript"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModal();
                      }}
                    >
                      {ForgotPassword}
                    </a>

                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "d-block w-100 mt-2",
                        onClick: (e) => loginAuth(e),
                      }}
                    >
                      {SignIn}
                    </Btn>
                  </div>
                </Form>
              </div>
            </div>
           
            <Modal isOpen={modalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>
                {resetPasswordStep === 1 ? "Forgot Password" : "Reset Password"}
              </ModalHeader>
              <ModalBody>
                {resetPasswordStep === 1 ? (
                  <FormGroup>
                    <Label for="forgotEmail">Email</Label>
                    <Input
                      type="email"
                      name="forgotEmail"
                      className="form-control"
                      id="forgotEmail"
                      placeholder="Enter your email"
                      onChange={(e) => handleForgotEmailChange(e.target.value)}
                      value={resetEmail} 
                    />
                  </FormGroup>
                ) : (
                  <Fragment>
                    
                    <Form>
                      <FormGroup>
                        <Label for="resetEmail">Email</Label>
                        <Input
                          type="email"
                          name="resetEmail"
                          id="resetEmail"
                          placeholder="Enter your email"
                          onChange={(e) =>
                            handleResetEmailChange(e.target.value)
                          }
                          value={resetEmail}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="resetPassword">New Password</Label>
                        <Input
                          type="password"
                          name="resetPassword"
                          id="resetPassword"
                          placeholder="Enter your new password"
                          onChange={(e) =>
                            handleResetPasswordChange(e.target.value)
                          }
                          value={resetPassword}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Confirm your new password"
                          onChange={(e) =>
                            handleConfirmPasswordChange(e.target.value)
                          }
                          value={confirmPassword}
                        />
                      </FormGroup>
                    </Form>
                  </Fragment>
                )}
              </ModalBody>
              <ModalFooter>
                {resetPasswordStep === 1 ? (
                  <Btn
                    attrBtn={{
                      color: "secondary",
                      onClick: toggleModal,
                    }}
                  >
                    Close
                  </Btn>
                ) : (
                  <Btn
                    attrBtn={{
                      color: "secondary",
                      onClick: () =>
                        setResetPasswordStep(resetPasswordStep - 1),
                    }}
                  >
                    Back
                  </Btn>
                )}
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: resetPasswordStep === 1 ? handleNext : () => {},
                  }}
                >
                  {resetPasswordStep === 1 ? "Next" : "Reset Password"}
                </Btn>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;