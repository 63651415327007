import React, {useState} from "react";
import {
    Row,
    Col,
    CardHeader,
    CardBody,
    Card,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import {Btn, H4, Breadcrumbs} from "../../../../AbstractElements";
import "./role.css";
import Switch from "react-switch";

const Role = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [display_name, setDisplayName] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [employeeToggle, setEmployeeToggle] = useState(false);
    const [holidayToggle, setHolidayToggle] = useState(false);
    const [eventToggle, setEventToggle] = useState(false);
    const [chatToggle, setChatToggle] = useState(false);
    const [jobToggle, setJobToggle] = useState([]);

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
    };

    const handleAddRole = () => {
        const requestData = {
            name,
            description,
            permissions,
            display_name,
            permissions,
        };

        fetch("https://punchr.ofeasy.lk/api/privilege/role", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Tenant": "client_a",
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Role added successfully:", data);

                setName("");
                setDescription("");
                setPermissions([]);
                setDisplayName("");
                setPermissions([]);
                setModalOpen(false);
            })
            .catch((error) => {
                console.error("Error adding role:", error);
            });
    };

    const toggleEmployee = () => {
        setEmployeeToggle(!employeeToggle);
    };

    const toggleHoliday = () => {
        setHolidayToggle(!holidayToggle);
    };

    const toggleEvent = () => {
        setEventToggle(!eventToggle);
    };

    const toggleChat = () => {
        setChatToggle(!chatToggle);
    };

    const toggleJob = () => {
        setJobToggle(!jobToggle);
    };

    const handleRoleClick = () => {
    }

    return (
        <div>
            <Breadcrumbs
                mainTitle="Role And Permission"
                parent="Privilege"
                title="Role And Permission"
            />
            <div className="row ">
                <div className="col-4">
                    <div className="">
                        <Card>
                            <div className="p-3" style={{marginLeft: "190px"}}>
                                <Button
                                    type="button"
                                    className="btn btn-secondary px-0 remove-padding"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Tooltip on bottom"
                                    style={{
                                        backgroundColor: "#3498db",
                                        borderColor: "#3498db",
                                        width: "120px",
                                    }}
                                    onClick={handleModalToggle}
                                >
                                    <i className="fas fa-plus"></i> Add Role
                                </Button>
                            </div>

                            <CardBody style={{maxHeight: "300px", overflowY: "auto"}}>
                                <div className="row mt-3 mb-3">
                                    <div className="col-md-6">
                                        <a
                                            href="#"
                                            onClick={() => handleRoleClick("Administrator")}
                                        >
                                            Administrator
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("CEO")}>
                                            CEO
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("Manager")}>
                                            Manager
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("Team Leader")}>
                                            Team Leader
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("Accountant")}>
                                            Accountant
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a
                                            href="#"
                                            onClick={() => handleRoleClick("Web Developer")}
                                        >
                                            Web Developer
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("Web Designer")}>
                                            Web Designer
                                        </a>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <a href="#" onClick={() => handleRoleClick("HR")}>
                                            HR
                                        </a>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="col-8">
                    <div>
                        <Card>
                            <CardBody>
                                <h6>Module Access</h6>
                                <div className="row mb-3 mt-3">
                                    <div className="col-md-10">
                                        <p>Employee</p>
                                    </div>
                                    <div className="col-md-2">
                                        <Switch
                                            onChange={toggleEmployee}
                                            checked={employeeToggle}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={40}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <p>Holiday</p>
                                    </div>
                                    <div className="col-md-2">
                                        <Switch
                                            onChange={toggleHoliday}
                                            checked={holidayToggle}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={40}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <p>Event</p>
                                    </div>
                                    <div className="col-md-2">
                                        <Switch
                                            onChange={toggleEvent}
                                            checked={eventToggle}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={40}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3 ">
                                    <div className="col-md-10">
                                        <p>Chat</p>
                                    </div>
                                    <div className="col-md-2">
                                        <Switch
                                            onChange={toggleChat}
                                            checked={chatToggle}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={40}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <p>Job</p>
                                    </div>
                                    <div className="col-md-2">
                                        <Switch
                                            onChange={toggleJob}
                                            checked={jobToggle}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={40}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div>
                        <Card>
                            <CardBody>
                                <div className="container">
                                    <div className="row mb-3 mt-3">
                                        <div className="col-md-12">
                                            <h6>Module Permission</h6>
                                        </div>
                                    </div>

                                    <div className="row mb-3 highlightRow">
                                        <div className="col-md-4">Module Permission</div>
                                        <div className="col-md-2">Create</div>
                                        <div className="col-md-2">Read</div>
                                        <div className="col-md-2">Write</div>
                                        <div className="col-md-2">Delete</div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">Employee</div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow1Col2"
                                                name="checkboxRow1Col2"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow1Col3"
                                                name="checkboxRow1Col3"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow1Col4"
                                                name="checkboxRow1Col4"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow1Col5"
                                                name="checkboxRow1Col5"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">Holiday</div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow1Col2"
                                                name="checkboxRow1Col2"
                                                style={{backgroundColor: "#3498db", color: "#fff"}}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col3"
                                                name="checkboxRow2Col3"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col4"
                                                name="checkboxRow2Col4"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col5"
                                                name="checkboxRow2Col5"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">Event</div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col2"
                                                name="checkboxRow2Col2"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col3"
                                                name="checkboxRow2Col3"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col4"
                                                name="checkboxRow2Col4"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col5"
                                                name="checkboxRow2Col5"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">Chat</div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col2"
                                                name="checkboxRow2Col2"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col3"
                                                name="checkboxRow2Col3"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col4"
                                                name="checkboxRow2Col4"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col5"
                                                name="checkboxRow2Col5"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">Job</div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col2"
                                                name="checkboxRow2Col2"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col3"
                                                name="checkboxRow2Col3"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col4"
                                                name="checkboxRow2Col4"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="checkbox"
                                                id="checkboxRow2Col5"
                                                name="checkboxRow2Col5"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalOpen} toggle={handleModalToggle}>
                <ModalHeader toggle={handleModalToggle}>Add Role</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input
                            type="text"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="display_name">Display Name</Label>
                        <Input
                            type="text"
                            id="display_name"
                            value={display_name}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="permissions">Permissions</Label>
                        <Input
                            type="text"
                            id="permissions"
                            value={permissions.join(", ")}
                            onChange={(e) => setPermissions(e.target.value.split(", "))}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="permissionss">Permissions</Label>
                        <Input
                            type="text"
                            id="permissionss"
                            value={permissions.join(", ")}
                            onChange={(e) => setPermissions(e.target.value.split(", "))}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddRole}>
                        Add Role
                    </Button>{" "}
                    <Button color="secondary" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Role;
