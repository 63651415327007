import React, { Fragment, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';

const AddNewBranch = () => {
  const [formData, setFormData] = useState({
    country_id: '',
    name: '',
    lat: '',
    lang: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  
  if (!formData.country_id || !formData.name) {
    console.error('Country ID and Name are required');
    return;
  }

  try {
    const response = await fetch('https://punchr.ofeasy.lk/api/organization/branches', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'X-Tenant': 'client_a'
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      console.log('Branch added successfully');
    } else {
      console.error('Failed to add branch');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};


  return (
    <Fragment>
      <Breadcrumbs mainTitle="Add Branch" parent="Organization" title="Branches" />
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="country_id">Country ID</Label>
                  <Input
                    type="text"
                    id="country_id"
                    name="country_id"
                    value={formData.country_id}
                    onChange={handleChange}
                  >
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="lat">Latitude</Label>
                  <Input
                    type="text"
                    id="lat"
                    name="lat"
                    value={formData.lat}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lang">Langtitude</Label>
                  <Input
                    type="text"
                    id="lang"
                    name="lang"
                    value={formData.lang}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit" color="primary">
              Add Branch
            </Button>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AddNewBranch;
