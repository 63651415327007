import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  Tooltip,
} from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [resetModal, setResetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editedUser, setEditedUser] = useState({
    id: null,
    name: "",
    email: "",
  });

  const toggleResetModal = () => setResetModal(!resetModal);
  const toggleViewModal = () => setViewModal(!viewModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleCreateModal = () => setCreateModal(!createModal);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://punchr.ofeasy.lk/api/privilege/user",
          {
            headers: {
              Accept: "application/json",
              "X-Tenant": "client_a",
              Authorization:"Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
            },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          setUsers(response.data.data);
        } else {
          console.error("Invalid response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const confirmDelete = async () => {
    try {
      console.log(`Deleting user with ID ${userIdToDelete}`);

      // Make DELETE request to the API
      await axios.delete(
        `https://punchr.ofeasy.lk/api/privilege/user/${userIdToDelete}`,
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      // Remove the deleted user from the state
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userIdToDelete)
      );

      // Close the delete modal
      toggleDeleteModal();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      // Make an HTTP PUT or PATCH request to update the user data
      const response = await axios.put(
        `https://punchr.ofeasy.lk/api/privilege/user/${editedUser.id}`,
        editedUser,
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization: "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      // Check the response and update the local state if successful
      if (response.data) {
        // Assuming the API response contains the updated user data
        const updatedUserData = response.data;

        // Update the local state with the updated user data
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === updatedUserData.id ? updatedUserData : user
          )
        );

        // Close the edit modal
        toggleEditModal();
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleView = (user) => {
    console.log(`View user with ID ${user.id}`);
    setSelectedUser(user);
    toggleViewModal();
  };

  const handleReset = (userId) => {
    console.log(`Reset password for user with ID ${userId}`);
    toggleResetModal();
  };

  const handleEdit = (user) => {
    console.log(`Edit user with ID ${user.id}`);
    // Set the edited user state when the Edit icon is clicked
    setEditedUser({
      id: user.id,
      name: user.name,
      email: user.email,
    });
    toggleEditModal();
  };

  // const handleSaveChanges = () => {
  //   // Perform actions to save changes, e.g., make an API request
  //   console.log("Saving changes for user with ID:", editedUser.id);
  //   // After saving changes, close the modal
  //   toggleEditModal();
  // };

  const handleDelete = (userId) => {
    console.log(`Delete user with ID ${userId}`);
    setUserIdToDelete(userId);
    toggleDeleteModal(); // Open the delete modal
  };

  const handleCreate = () => {
    console.log("Open create form");
    toggleCreateModal();
  };

  // const confirmDelete = () => {
  //   console.log(`Deleting user with ID ${userIdToDelete}`);
  //   toggleDeleteModal();
  // };

  // const handleView = (userId) => {
  //   console.log(`View user with ID ${userId}`);
  // };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Users List" parent="Privilege" title="Users" />
      <Card>
        <div
          className="mb-3 d-flex"
          style={{ marginTop: "20px", marginLeft: "1050px" }}
        >
          <Tooltip
            placement="bottom"
            target="addUserButton"
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            Add New User
          </Tooltip>
          <Button id="addUserButton" color="warning" onClick={handleCreate}>
            <i className="fa fa-plus"></i> Add New User
          </Button>
        </div>
        <CardBody>
          <Table responsive striped bordered>
            <thead>
              <tr style={{ backgroundColor: "#FFA500" }}>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {users.length > 0 ? (
                users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fa fa-key"
                          onClick={() => handleReset(user.id)}
                          style={{ color: "blue" }}
                        ></i>
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                          <i
                          className="fa fa-edit"
                          onClick={() => handleEdit(user)}
                          style={{ color: "green", cursor: "pointer" }}
                        ></i>
                        </div>
                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                          <i
                            className="fa fa-trash"
                            onClick={() => handleDelete(user.id)}
                            style={{ color: "red", cursor: "pointer" }}
                          ></i>
                        </div>
                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                          <i
                            className="fa fa-eye"
                            onClick={() => handleView(user)}
                            style={{ color: "blue", cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No users available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={resetModal} toggle={toggleResetModal}>
        <ModalHeader toggle={toggleResetModal}>
          Reset Password Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to reset the password?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleResetModal}>
            Yes, Reset
          </Button>{" "}
          <Button color="secondary" onClick={toggleResetModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      
      {/* Edit User Modal */}
     <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            {/* Set the input value to the edited user's name */}
            <Input
              type="text"
              id="name"
              value={editedUser.name}
              onChange={(e) =>
                setEditedUser({ ...editedUser, name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            {/* Set the input value to the edited user's email */}
            <Input
              type="email"
              id="email"
              value={editedUser.email}
              onChange={(e) =>
                setEditedUser({ ...editedUser, email: e.target.value })
              }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

     <Modal isOpen={deleteModal} toggle={toggleDeleteModal} centered>
  <ModalHeader toggle={toggleDeleteModal}>
    Delete User Confirmation
  </ModalHeader>
  <ModalBody>Are you sure you want to delete this user?</ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={confirmDelete}>
      Yes, Delete
    </Button>{" "}
    <Button color="secondary" onClick={toggleDeleteModal}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>


      <Modal isOpen={createModal} toggle={toggleCreateModal}>
        <ModalHeader toggle={toggleCreateModal}>Create User</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" id="name" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" id="email" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Status</Label>
            <Input type="email" id="email" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleCreateModal}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggleCreateModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* View User Modal */}
      <Modal isOpen={viewModal} toggle={toggleViewModal}>
        <ModalHeader toggle={toggleViewModal}>View User Details</ModalHeader>
        <ModalBody>
          {selectedUser && (
            <Table>
              <tbody>
                <tr>
                  <td>ID:</td>
                  <td>{selectedUser.id}</td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{selectedUser.name}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{selectedUser.email}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Users;
