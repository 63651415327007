import React, { Fragment, useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
  CardHeader,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { Btn, H4 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Approvals = () => {
const [isEditModalOpen, setEditModalOpen] = useState(false);

  const userData = [
    {
      id: 1,
      name: "shift1",
      location: "Dubai",
      project: "project1",
    },
    {
      id: 2,
      name: "JShift2",
      location: "Sri Lanka",
      project: "project2",
    },
    {
      id: 3,
      name: "Shift3",
      location: "Dubai",
      project: "project3",
    },
  ];

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Approvals" parent="Approvals" title="Approval List" />
      <CardBody>
        <div
          className="mb-3"
          style={{ marginLeft: "650px", marginTop: "20px" }}
        >
          <Link to={`${process.env.PUBLIC_URL}/approvals-two/:layout`}>
            <Btn>
              <i className="fa fa-plus"></i> Create Approvals Matrix
            </Btn>
          </Link>
        </div>
        <div className="row">
          <div className="col">
            <Card style={{ width: "890px" }}>
              <Table responsive striped bordered>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Location</th>
                    <th>Projects</th>
                    <th>Approvers</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.location}</td>
                      <td>{user.project}</td>
                      <td style={{ color: "blue", textAlign: "center" }}>
                        <i className="fa fa-edit" onClick={handleEditClick}></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
          <div className="col">
            <CardBody>
              <Card style={{ width: "300px" }}>
              
                <div className="alignCenter">
                  <DropdownButton title="Location" variant="warning">
                    <Dropdown.Item
                      as={Link}
                      to={`${process.env.PUBLIC_URL}/layout1`}
                      style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
                    >
                      Layout 1
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`${process.env.PUBLIC_URL}/layout2`}
                      style={{ backgroundColor: "#00FF00", color: "#000000" }}
                    >
                      Layout 2
                    </Dropdown.Item>
                  </DropdownButton>
                </div>

                <div className="alignCentertwo">
                  <DropdownButton title="Project" variant="warning">
                    <Dropdown.Item
                      as={Link}
                      to={`${process.env.PUBLIC_URL}/layout1`}
                      style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
                    >
                      Layout 1
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`${process.env.PUBLIC_URL}/layout2`}
                      style={{ backgroundColor: "#00FF00", color: "#000000" }}
                    >
                      Layout 2
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card>
            </CardBody>
          </div>
        </div>
      </CardBody>

       <Modal isOpen={isEditModalOpen} toggle={handleModalClose}>
        <ModalHeader toggle={handleModalClose}>Edit User</ModalHeader>
        <ModalBody>
         
          <FormGroup>
            <Label for="editName">Employee Name</Label>
            <Input type="text" name="editName" id="editName" />
          </FormGroup>
          <FormGroup>
            <Label for="editName">Project</Label>
            <Input type="text" name="editName" id="editName" />
          </FormGroup>
          <FormGroup>
            <Label for="editName">Location</Label>
            <Input type="text" name="editName" id="editName" />
          </FormGroup>
         
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleModalClose}>
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Approvals;
