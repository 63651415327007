import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, Password, SignUp, UsersName } from "../Constant";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import man from "../assets/images/dashboard/profile.png";
import "./signin.css";

const Signup = ({ selected }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const validateForm = () => {
    if (!name.trim() || !email.trim() || !password.trim() || !confirmPassword.trim()) {
      toast.error("All fields are required!..");
      return false;
    }

    if (password !== confirmPassword) {
      toast.error("Password and confirm password do not match!..");
      return false;
    }

    return true;
  };

  const signUp = async () => {
  if (!validateForm()) {
    return;
  }

  try {
    const requestConfig = {
      url: "https://punchr.ofeasy.lk/api/authentication/register",
      method: "post",
      data: {
        name,
        email,
        password,
        confirmPassword,
      },
      headers: {
        "Accept": "application/json",
        "X-Tenant": "client_a",
      },
    };

    const response = await axios(requestConfig);

    if (response.status !== 200) {
      toast.error(`Error: ${response.data.message}`);
      return;
    }

     const token = response.data.token;
      localStorage.setItem("token", token);

    history(`${process.env.PUBLIC_URL}/login`);
    toast.success("Successfully signed up! 🎉", { autoClose: 3000 });
  } catch (error) {
    console.error("Error occurred during signup:", error);
    toast.error("An error occurred during signup. Please try again.");
  }
};


  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col
            xs="12"
            lg="6"
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src="https://img.freepik.com/premium-vector/data-analytics-dashboard_165488-4224.jpg?w=2000"
              alt="Profile"
              className="img-fluid"
            />
          </Col>
          <Col xs="12" lg="6">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4 className="logintopic">
                    {selected === "simpleLogin" ? "" : "Register"}
                  </H4>
                  <P>{"Sign up for instant access! 🚀"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{UsersName}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">Confirm Password</Label>
                    <Input
                      className="form-control"
                      type="password"
                      placeholder="Confirm your password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <Button
                      color="primary"
                      className="d-block w-100 mt-2"
                      onClick={signUp}
                    >
                      {SignUp}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signup;
