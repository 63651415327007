import React, { useEffect, useState } from 'react';
import { Col, Row, Progress, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import axios from 'axios';
import { H2, P } from '../../../../AbstractElements';
import { Location, EmployeeList, Done } from '../../../../Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const CusClass = () => {
  const [branchData, setBranchData] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editBranchData, setEditBranchData] = useState({
    name: '',
    lat: '',
    lang: '',
  });

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await axios.get('https://punchr.ofeasy.lk/api/organization/branches', {
           headers: {
              Accept: "application/json",
              "X-Tenant": "client_a",
               Authorization: "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
            },
        });

        if (response.status === 200) {
          setBranchData(response.data.data);
        } else {
          console.error('Failed to fetch branch data:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error fetching branch data:', error.message);
      }
    };

    fetchBranchData();
  }, []);

  const handleEdit = (itemId) => {
    const branchToEdit = branchData.find((item) => item.country_id === itemId);

    setEditBranchData({
      name: branchToEdit.name,
      lat: branchToEdit.lat,
      lang: branchToEdit.lang,
    });

    setIsEditModalOpen(true);
  };

  const handleDelete = (itemId) => {
    console.log('Delete clicked for item ID:', itemId);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditBranchData({
      ...editBranchData,
      [name]: value,
    });
  };

  const saveChanges = () => {
    // Implement logic to save changes (e.g., send a request to update the branch data)
    console.log('Saving changes:', editBranchData);
    // Close the modal
    toggleEditModal();
  };

  return (
    <Col className='col-xxl-4' md='6'>
      {branchData.map((item, index) => (
        <div className='project-box' key={item.country_id} style={{ position: 'relative', backgroundColor: index % 2 === 0 ? '#F9F9F9' : '#EFEFEF', border: '1px solid #DDD' }}>
          <div style={{ position: 'absolute', top: '5px', right: '5px', zIndex: '1' }}>
            <Row>
              <Col>
                <span className='mr-2 text-primary cursor-pointer' onClick={() => handleEdit(item.country_id)}>
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              </Col>
              <Col>
                <span className='text-danger cursor-pointer' onClick={() => handleDelete(item.country_id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              </Col>
            </Row>
          </div>
          <h2 style={{ color: '#00B4D0', marginBottom: '10px' }}>{item.name}</h2>
          <Row>
            <Col>
              <h6 style={{ color: '#007BFF' }}>Lat & Lang</h6>
            </Col>
            <Col>
              <P>{`${item.lat}, ${item.lang}`}</P>
            </Col>
          </Row>
          <Row className='details'>
            <Col xs='6'>
              <span>{EmployeeList}</span>
            </Col>
            <Col xs='6' style={{ color: '#FF0000' }}>
              {item.country_id}
            </Col>
            <Col xs='6'>
              {' '}
              <span style={{ color: '#28A745' }}>{Location} </span>
            </Col>
            <Col xs='6' className='font-primary'>
              {`${item.lat}, ${item.lang}`}
            </Col>
          </Row>
          <div className='project-status mt-4'>
            <div className='media mb-0'>
              <P>{'100%'} </P>
              <div className='media-body text-end'>
                <span style={{ color: '#28A745' }}>{Done}</span>
              </div>
            </div>
            <Progress className='sm-progress-bar' color='success' value={100} style={{ height: '5px' }} />
          </div>
        </div>
      ))}

      {/* Modal for Editing */}
      <Modal isOpen={isEditModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Branch</ModalHeader>
        <ModalBody>
          <form>
            <div className='mb-3'>
              <label htmlFor='name' className='form-label'>Name</label>
              <input type='text' className='form-control' id='name' name='name' value={editBranchData.name} onChange={handleInputChange} />
            </div>
            <div className='mb-3'>
              <label htmlFor='lat' className='form-label'>Latitude</label>
              <input type='text' className='form-control' id='lat' name='lat' value={editBranchData.lat} onChange={handleInputChange} />
            </div>
            <div className='mb-3'>
              <label htmlFor='lang' className='form-label'>Longitude</label>
              <input type='text' className='form-control' id='lang' name='lang' value={editBranchData.lang} onChange={handleInputChange} />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={saveChanges}>Save Changes</Button>
          <Button color='secondary' onClick={toggleEditModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default CusClass;
