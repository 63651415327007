import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCalendar,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import Stepper from "awesome-react-stepper";
import { Card, CardHeader, CardBody, FormGroup } from "reactstrap";
import { H3, Btn, Breadcrumbs } from "../../../../AbstractElements";
import "../Screen/employee.css";

const EmployeeAdd = () => {
  const [currentStep, setCurrentStep] = useState(0);
   const [firstName, setFirstName] = useState('');

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const [formData, setFormData] = useState({
    employee_id: "",
    user_id: "",
    designation_id: "",
    first_name: "",
    last_name: "",
    gender_id: "",
    device_id: "",
    location_id: "",
     location_type: "",
  });

  const handleSubmit = async (step) => {
    try {
      
      const response = await fetch("https://punchr.ofeasy.lk/api/organization/employees", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-tenant": "client_a"
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert(`Employee added successfully! Final Step -> ${step}`);
      } else {
       
        console.error("Failed to add employee");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const stepIcons = [faUser, faEnvelope, faCalendar, faLanguage];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="pt-3">
       <Breadcrumbs mainTitle="Add Employee" parent="Employee List" title="Add Employee" />
      <Card>
        <CardHeader>
          <H3>Add Employee</H3>
        </CardHeader>
        <CardBody>
          <Stepper
            fillStroke="#800080"
            activeColor="#800080"
            activeProgressBorder="2px solid #800080"
            submitBtn={<button className="stepperBtn">Submit</button>}
            continueBtn={<button className="stepperBtn">Next</button>}
            backBtn={<button className="stepperBtn">Back</button>}
            onSubmit={handleSubmit}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            iconComponent={(step, isActive) => (
              <FontAwesomeIcon
                icon={stepIcons[step]}
                size="lg"
                color={isActive ? "#800080" : "#ccc"}
              />
            )}
          >
            <div className="stepperSubDiv">
              <h4>Personal Information</h4>
              <div className="row">
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Employee ID</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="employeeid"
                      value={formData.employee_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>User ID</label>
                    <input
                      type="text"
                      name="age"
                      className="form-control"
                      placeholder="userid"
                      value={formData.user_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>

               <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Gender Id</label>
                    <input
                      type="text"
                      name="age"
                      className="form-control"
                      placeholder="genderid"
                      value={formData.gender_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="stepperSubDiv">
              <h4>Location & Project details</h4>
              <div className="row">
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Designation ID</label>
                    <input
                      type="text"
                      name="projectname"
                      className="form-control"
                      placeholder="designationid"
                      value={formData.designation_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup className="pt-4" style={{ width: "100%" }}>
                    <label>Location ID</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      placeholder="locationid"
                       value={formData.location_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Device Id</label>
                    <input
                      type="text"
                      name="branch"
                      className="form-control"
                      placeholder="deviceid"
                       value={formData.device_id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FormGroup style={{ width: "100%" }}>
                    <label>Location Type</label>
                    <input
                      type="text"
                      name="language"
                      className="form-control"
                      placeholder="locationtype"
                        value={formData.location_type}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Stepper>
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployeeAdd;


