import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [editForm, setEditForm] = useState({
    name: "",
   
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("https://punchr.ofeasy.lk/api/organization/clients", {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization: "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        });

        console.log("API Response:", response);

        if (response.data && Array.isArray(response.data.data)) {
          setClients(response.data.data);
        } else {
          console.error("API response does not contain the expected array structure:", response.data);
          setError("API response does not contain the expected array structure");
          setClients([]);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
        setError("Error fetching clients. Please try again later.");
        setClients([]);
      }
    };

    fetchClients();
  }, []);

   const handleEyeIconClick = (client) => {
    setSelectedClient(client);
    setModalOpen(true);
  };

  const handleEditIconClick = (client) => {
    setSelectedClient(client);
    setEditForm({
      name: client.name,
      // Set other form fields based on your client data structure
    });
    setModalOpen(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add logic to submit the edited form data
    console.log("Form submitted:", editForm);
    // Close the modal after submitting
    setModalOpen(false);
  };

  const renderTable = () => {
    return (
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td>{client.name}</td>
              <td>
                <div className="row">
                  <div className="col-2">
                    <span
                      className="mr-4 text-primary cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </div>
                  <div className="col-2">
                    <span
                      className="mr-4 text-success cursor-pointer"
                      onClick={() => handleEyeIconClick(client)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </div>
                  <div className="col-2">
                    <span
                      className="text-danger cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderModal = () => {
    return (
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Client Details</ModalHeader>
        <ModalBody>
          {selectedClient && (
            <div>
              <p>Client Name: {selectedClient.name}</p>
              {/* Add more client details here */}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      
    );
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Clients List" parent="Clients Management" title="Clients" />
      <Card>
        <CardBody>
          {error ? (
            <p>{error}</p>
          ) : clients.length > 0 ? (
            <Fragment>
              {renderTable()}
              {renderModal()}
            </Fragment>
          ) : (
            <p>No clients available</p>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Clients;
