import React, { useState, Fragment, useContext } from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Btn, H4, P } from "../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../../Constant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CustomizerContext from "../../_helper/Customizer";

const Login = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const loginAuth = async () => {
    try {
      // const requestConfig = {
      //   url: "https://punchr.ofeasy.lk/api/authentication/login",
      //   method: "post",
      //   data: {
      //     email,
      //     password,
      //   },
      //   headers: {
      //     Accept: "application/json",
      //     "X-Tenant": "client_a",
      //   },
      // };

      // const response = await axios(requestConfig);

      // const token = response.data.token;
      const token = '5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56';

      history(`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`);

      toast.success("Successfully logged in!..");
    } catch (error) {
      console.error(error);
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>
                    {selected === "simpleLogin"
                      ? ""
                      : "Sign In With Simple Login"}
                  </H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <h5
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModal();
                      }}
                    >
                      Forgot Password
                    </h5>

                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "d-block w-100 mt-2",
                        onClick: (e) => loginAuth(e),
                      }}
                    >
                      {SignIn}
                    </Btn>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for Forgot Password */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Forgot Password</ModalHeader>
        <ModalBody>
          {/* Add your content for the forgot password form inside the modal body */}
          <p>Put your forgot password form here...</p>
        </ModalBody>
        <ModalFooter>
          <Btn
            attrBtn={{
              color: "secondary",
              onClick: toggleModal,
            }}
          >
            Close
          </Btn>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </Fragment>
  );
};

export default Login;
