import React from 'react';
import { Media } from 'reactstrap';
import { Image } from '../../AbstractElements';
import user1 from '../../assets/images/user/1.jpg';
import user2 from '../../assets/images/user/2.png';
import user3 from '../../assets/images/user/3.jpg';
import user4 from '../../assets/images/user/3.png';
import user5 from '../../assets/images/user/4.jpg';
import user6 from '../../assets/images/user/5.jpg';
import user7 from '../../assets/images/user/6.jpg';
import user8 from '../../assets/images/user/7.jpg';
import user9 from '../../assets/images/user/8.jpg';
import user10 from '../../assets/images/user/9.jpg';
import user11 from '../../assets/images/user/10.jpg';
import user12 from '../../assets/images/user/11.png';
import user13 from '../../assets/images/user/12.png';

export const dummytabledata = [
    {
        id: 1,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user1}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Airi Satou</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'
        

    },
    {
        id: 2,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user2}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Thomas Taylor</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'
    },
    {
        id: 3,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user3}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Michael Morris</div>
            </Media>
        </Media>,
       position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'
    },
    {
        id: 4,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user4}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Tiger Nixon</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'

    },
    {
        id: 5,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user5}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Garrett Winters</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'

    },
    
   
];



export const tableColumns = [
  {
    name: 'Employee ID',
    selector: 'employee_id',
    sortable: true,
  },
  {
    name: 'User ID',
    selector: 'user.id',
    sortable: true,
  },
 {
    name: 'Designation ID',
    selector: 'designation.name', 
    sortable: true,
  },
  {
    name: 'First Name',
    selector: 'first_name',
    sortable: true,
  },
  {
    name: 'Last Name',
    selector: 'last_name',
    sortable: true,
  },
];


export const dummytabledat = [
    {
    id: 1,
    name: (
        <Media className='d-flex'>
            <Image
                attrImage={{
                    className: 'rounded-circle img-30 me-3',
                    src: `${user1}`,
                    alt: 'Generic placeholder image'
                }}
            />
            <Media body className="align-self-center">
                <div>Airi Satou</div>
            </Media>
        </Media>
    ),
    email: 'test123@gmail.com',
    registered: 'registered',
    status: '32',
    reset: '2023/04/27',
    action: (
        <div>
            <button >Edit</button>
            <button >Delete</button>
            <button >Update</button>
        </div>
    )
},

    {
        id: 2,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user2}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Thomas Taylor</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'
    },
    {
        id: 3,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user3}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Michael Morris</div>
            </Media>
        </Media>,
       position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'
    },
    {
        id: 4,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user4}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Tiger Nixon</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'

    },
    {
        id: 5,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user5}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Garrett Winters</div>
            </Media>
        </Media>,
        position: 'Senior Software Engineer',
        office: 'Office 1',
        age: '32',
        startdate: '2023/04/27',
        salary: '$5000'

    },

];




export const tableColumn = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Email',
        selector: row => `${row.email}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Registered On',
        selector: row => `${row.registered}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Status',
        selector: row => `${row.status}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Registered Password',
        selector: row => `${row.reset}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Action',
        selector: row => `${row.action}`,
        sortable: true,
        center: true,
    }
    
];