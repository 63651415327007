import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleView = (device) => {
    setSelectedDevice(device);
    toggleViewModal();
  };

  const handleEdit = (device) => {
    setSelectedDevice(device);
    toggleEditModal();
  };

  const confirmDelete = () => {
    toggleDeleteModal();
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(
        `https://punchr.ofeasy.lk/api/attendance/devices/${selectedDevice.id}`,
        {
          name: document.getElementById("newDeviceName").value,
          type_id: document.getElementById("newDeviceType").value,
          ip_address: document.getElementById("newIpAddress").value,
        },
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      console.log("Device updated successfully:", response.data);
      toggleEditModal();
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://punchr.ofeasy.lk/api/attendance/devices",
          {
            headers: {
              Accept: "application/json",
              "X-Tenant": "client_a",
              Authorization:
                "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
            },
          }
        );
        console.log("API Response:", response.data);

        if (Array.isArray(response.data.data)) {
          setDevices(response.data.data);
        } else {
          console.error(
            "API response data is not an array:",
            response.data.data
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async () => {
    try {
     
      const response = await axios.delete(
        `https://punchr.ofeasy.lk/api/attendance/devices/${selectedDevice.id}`,
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      console.log("Device deleted successfully:", response.data);
      toggleDeleteModal(); 
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };


  const handleCreateChanges = async () => {
    try {
      
      const deviceData = {
        device_id: document.getElementById("newDeviceID").value,
        type_id: document.getElementById("newDeviceType").value,
        name: document.getElementById("newDeviceName").value,
        ip_address: document.getElementById("newIpAddress").value,
      };

      const response = await axios.post(
        "https://punchr.ofeasy.lk/api/attendance/devices",
        deviceData,
        {
          headers: {
            Accept: "application/json",
            "X-Tenant": "client_a",
            Authorization:
              "Bearer 5|JaNO0z8CB6WcuJNhAay6z0mI34KLLpHhhPyhl49Uedd87c56",
          },
        }
      );

      console.log("Device added successfully:", response.data);
      toggleAddModal();
    } catch (error) {
      console.error("Error adding new device:", error);
    }
  };

 

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Device List"
        parent="Attendance"
        title="Devices"
      />
      <Card>
        <div
          className="mb-3"
          style={{ marginLeft: "950px", marginTop: "20px" }}
        >
          <Button onClick={toggleAddModal} color="primary">
            <i className="fa fa-plus"></i> Add New Device
          </Button>
        </div>

        <CardBody>
          <Table responsive striped bordered className="devicetab">
            <thead>
              <tr>
                <th>Device ID</th>
                <th>Device Name</th>
                <th>Device Type</th>
                <th>IP Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device.device_id}>
                  <td>{device.device_id}</td>
                  <td>{device.name}</td>
                  <td>{device.type.name}</td>
                  <td>{device.ip_address}</td>

                  <td>
                    <div className="row">
                      <div className="col-2">
                        <i
                          className="fa fa-edit"
                          style={{
                            color: "blue",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEdit(device)}
                        ></i>
                      </div>
                      <div className="col-2">
                        <i
                          className="fa fa-eye"
                          style={{
                            color: "green",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleView(device)}
                        ></i>
                      </div>

                      <div className="col-2">
                        <i
                          className="fa fa-trash"
                          style={{
                            color: "red",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedDevice(device);
                            toggleDeleteModal();
                          }}
                        ></i>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Device</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="newDeviceType">Device ID</Label>
            <Input
              type="text"
              id="newDeviceType"
              defaultValue={selectedDevice ? selectedDevice.id : ""}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="newDeviceName">Device Name</Label>
            <Input
              type="text"
              id="newDeviceName"
              defaultValue={selectedDevice ? selectedDevice.name : ""}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newDeviceType">Device Type</Label>
            <Input
              type="text"
              id="newDeviceType"
              defaultValue={selectedDevice ? selectedDevice.type.name : ""}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newIpAddress">IP Address</Label>
            <Input
              type="text"
              id="newIpAddress"
              defaultValue={selectedDevice ? selectedDevice.ip_address : ""}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={addModal} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Add New Device</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="newDeviceID">Device ID</Label>
            <Input type="text" id="newDeviceID" />
          </FormGroup>
          <FormGroup>
            <Label for="newDeviceName">Device Name</Label>
            <Input type="text" id="newDeviceName" />
          </FormGroup>
          <FormGroup>
            <Label for="newDeviceType">Device Type</Label>
            <Input type="text" id="newDeviceType" />
          </FormGroup>
          <FormGroup>
            <Label for="newIpAddress">IP Address</Label>
            <Input type="text" id="newIpAddress" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreateChanges}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggleAddModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={viewModal} toggle={toggleViewModal}>
        <ModalHeader toggle={toggleViewModal}>View Device Details</ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              <tr>
                <th>Device ID</th>
                <td>{selectedDevice ? selectedDevice.device_id : ""}</td>
              </tr>
              <tr>
                <th>Device Name</th>
                <td>{selectedDevice ? selectedDevice.name : ""}</td>
              </tr>
              <tr>
                <th>Device Type</th>
                <td>{selectedDevice ? selectedDevice.type.name : ""}</td>
              </tr>
              <tr>
                <th>IP Address</th>
                <td>{selectedDevice ? selectedDevice.ip_address : ""}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          Delete Shift Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this device?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Yes, Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DeviceList;
